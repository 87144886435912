import { AfterViewInit, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthService } from '@services/auth.service';
import { LoaderService } from '@services/support/loader.service';
import { WorkflowService } from '@services/workflow.service';
import { Subscription } from 'rxjs';
import { QuillEditorComponent } from 'ngx-quill';
import "quill-mention";
import Quill from 'quill';
import { MentionBlot, Mention } from 'quill-mention';
//Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention }); this is default mentionblot if you use this remove blotName field from mention config properties

const CUSTOM_BLOT_NAME = 'styled-mention';
class StyledMentionBlot extends MentionBlot {
  static override create(data) {
    const element = document.createElement('a');
    element.className = 'mention';
    element.innerText= data.name;
    element.setAttribute('data-id', data.id);
    return element;
  }
}
StyledMentionBlot.blotName = CUSTOM_BLOT_NAME;
Quill.register({ 'blots/mention': StyledMentionBlot, 'modules/mention': Mention });


@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, AfterViewInit {
  @Input() workOrderId?
  @Input() comments

  commentTexts = []

  users = []
  filteredUsers = [];

  commentText: string = '';
  safeHtml: SafeHtml;
  currentUserId;

  myComment = ''

  subscriptions: Subscription[] = []
  isCommentSending = false

  // Add a new property to track if the editor has content
  hasContent: boolean = false;

  @ViewChild(QuillEditorComponent) quillRef!: QuillEditorComponent;


  modules = {
    toolbar: false,
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      spaceAfterInsert: true,
      mentionDenotationChars: ["@"],
      dataAttributes: ['id', 'name'],
      blotName: CUSTOM_BLOT_NAME, //Enable this when you want to create custom item for mention,
      //onSelect: (item, insertItem) => {
      //  insertItem({ id: item.id, value: item.value, age: item.age }, true, { blotName: 'styled-mention' })
      //},
      renderItem: (data) => {
        return data.name; // Used for dropdown list rendering, should return string or html element
      },
      source: (searchTerm, renderList, mentionChar) => {
        if (searchTerm.length === 0) {
          renderList(this.users, searchTerm);
        } else {
          const matches = [];
          this.users.forEach(entry => {
            if (
              entry.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      }
    }
  }

  constructor(
    private workflowService: WorkflowService,
    private sanitizer: DomSanitizer,
    private loaderService: LoaderService,
    private authService: AuthService,
    private zone: NgZone,
  ) { }

  get editor() {
    return this.quillRef.quillEditor;
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.workflowService.getUsers().then(result => {
      this.users = result.users
    })
    this.currentUserId = this.authService.currentUser.id;
    this.commentTexts = this.comments ? this.comments : []
  }

  byPassHTML(html: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  chooseButtonUpload(event: any): void {
    let selectedFile = event.target.files
    this.previewUpload(selectedFile)
  }

  getContent(event) {
    // Update hasContent based on editor's text content
    this.zone.run(() => {
      this.hasContent = this.editor.getText().trim().length > 0;
    });
  }

  private previewUpload(selectedFile: File): void {
    if (selectedFile) {
      this.loaderService.show()
      this.subscriptions.push(this.uploadfile(selectedFile).subscribe({
        next: (resp) => {
          if (resp) {
            let image
            image = []
            // resp['data'].forEach(imageObject => {
            //   this.files.push({name:imageObject.fieldname,url:imageObject.url})
            // })
            this.loaderService.hide()
          }
        },
        error: (err) => {
          this.loaderService.hide()
        }
      })
      )
    }
  }

  uploadfile(file) {
    let formData = new FormData();

    if (file.length) {
      for (let i = 0; i < file.length; i++) {
        formData.append(file[i].name, file[i])
      }
    } else {
      formData.append(file.name, file)
    }

    return this.workflowService.uploadWorkflowFiles(this.workOrderId, formData)

  }

  sendComment() {
    // Get the text content from the editor, removing any whitespace
    const textContent = this.editor.getText().trim();
    
    // If there's no text content, don't proceed
    if (!textContent) {
      return;
    }

    this.isCommentSending = true;
    const comment = {
      text: this.commentText
    }
    const mentions = this.editor.getContents().ops.reduce((acc, { insert }) => {
      if (typeof insert !== "string") {
        acc.push(insert[CUSTOM_BLOT_NAME]['id']);
      }
      return acc;
    }, []);

    this.workflowService.sendComment(comment, this.workOrderId).then(res => {
      this.commentTexts = res.workorder.comments
    })
    .finally(() => {
      this.commentText = ''
      this.isCommentSending = false
    })
  }

  getUsersName(uid) {
    const user = this.users.find(u => u.id === uid)
    if (user) {
      return user.name
    } else {
      return ""
    }
  }

}
