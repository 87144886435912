<div [formGroup]="workOrderForm" [ngClass]="{'detail-on-mobile': isMobile}">
    <div class="d-flex card list-header flex-row justify-content-between align-items-center detail-header">
        <ng-container *ngIf="!viewMode">
            <div class="d-flex justify-content-between align-items-center w-100">
                <label class="fs-20 font-weight-700">
                    <img src="assets/img/back-blue.svg" alt="" class="me-2 mb-1 cursor-pointer" (click)="back()">
                    {{mode === 'create' ? 'Create Work Order' : 'Edit Work Order'}}</label>
                <button *ngIf="mode !== 'create'" class="v-btn btn btn-primary vsight-button create-button" (click)="saveChanges()">{{ 'MAIN.CONTENT.WORK-ORDERS.SAVE-CHANGES'| translate }}</button>
                <button *ngIf="mode === 'create'" class="v-btn btn btn-primary vsight-button create-button" (click)="createWorkOrder()">{{ 'MAIN.CONTENT.WORK-ORDERS.CREATE'| translate }}</button>
            </div>
            
        </ng-container>
        <ng-container *ngIf="viewMode">
            <div class="d-flex justify-content-between align-items-center w-100">
                <label class="fs-20 font-weight-700">
                <img src="assets/img/back-blue.svg" alt="" class="me-2 mb-1 cursor-pointer" (click)="back()">
                {{selectedWorkOrder.name}}</label>
                <div *ngIf="!isWebClient">
                    <button *ngIf="mode !== 'create'" class="v-btn btn btn-primary vsight-button create-button me-3" (click)="deleteWorkOrder()">{{ 'MAIN.CONTENT.WORK-ORDERS.DELETE'| translate }}</button>
                    <button class="v-btn btn btn-primary vsight-button create-button" (click)="editWorkOrder()">{{ 'MAIN.CONTENT.WORK-ORDERS.EDIT'| translate }}</button>
                </div>
            </div>
        </ng-container>
        
    </div>

    <div>
        <div class="card tab-area d-flex flex-row first-area">
            <div class="tab-item left fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 0 }" (click)="onTabClicked(0)">
                <span class="tab-title">
                    Details
                </span>
            </div>
            <div class="tab-item right fs-16 font-weight-600 ms-4" [ngClass]="{'selected-tab' : tabIndex == 1 }" (click)="onTabClicked(1)">
                <span class="tab-title">
                    Comments
                </span>
            </div>
        </div>
    </div>

   <ng-container *ngIf="tabIndex == 0">
        <div class="order-wrapper d-flex flex-column align-items-center" *ngIf="!viewMode">
            <div class="d-flex align-items-center w-100">
                <label class="fs-14 font-weight-600" style="width: 140px;">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.TASK-NAME'| translate }}</label>
                <input type="text" class="form-control ms-3 fs-14" formControlName="name">
            </div>
        </div>

        <div class="order-wrapper d-flex flex-column align-items-center">
            <div class="d-flex align-items-center w-100 mb-4">
                <label class="fs-14 font-weight-600" style="width: 122px;">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.STATUS'| translate }}</label>
                <!-- <input type="text" class="form-control ms-3 fs-14"> -->
                <div class="btn-group dropdown-wrapper-list status-wrapper-list ps-0 pe-0" dropdown style="width: 180px;border: none;">
                    <button type="button" class="btn btn-primary action-dropdown status-dropdown {{status.value}} p-0 text-start ps-2 hover-style fs-15"  #toggleButton dropdownToggle data-bs-auto-close="inside">
                        <img src="assets/img/{{status.value}}.svg" alt="" class="status-dropdown-icon">
                        {{ 'MAIN.CONTENT.WORK-ORDERS.STATUS.' + status.value | translate | titlecase }}
                    </button>
                    <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split status-toggle {{status.value}}"  (click)="$event.stopPropagation(); toggleButton.click()"
                            aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                    </button>
                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                    overflow-y: scroll; min-width: 130px;" aria-labelledby="dropdownMenuClickableInside">
                        <li role="menuitem" *ngFor="let status of statusOptions">
                            <a class="dropdown-item fs-14 lato-regular" (click)="changeStatus(status)">
                                {{ 'MAIN.CONTENT.WORK-ORDERS.STATUS.' + status | translate | titlecase }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex align-items-center w-100 mb-4">
                <label class="fs-14 font-weight-600" style="width: 122px;">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.DUE-DATE'| translate }}</label>
                <!-- <input type="text" class="form-control ms-3 fs-14"> -->
                <div class="d-flex align-items-center lato-regular" style="height: 90%;" *ngIf="!viewMode">
                    <input #startDate="bsDatepicker" formControlName="dueDate"  name="startDate" class="form-control fs-14" id="form-control" style="width: 178px;" bsDatepicker 
                    autocomplete="off"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-blue', customTodayClass: 'datepicker-today' }" onkeypress="return false;" >
                    <span style="margin-left: -25px; margin-bottom: 4px;">
                    <img src="assets/img/calendar-icon.svg" style="height: 17px;">
                    </span>
                    <a class="due-time" (click)="addDueTime()" *ngIf = "!showDueTime">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.ADD-DUE-TIME'| translate }}</a>
                    <div class="estimated-duration-wrapper p-relative" *ngIf="showDueTime">
                        <timepicker [formControl]="dueTime" [showMeridian]="false" style="position: absolute; top: -24px;
                        left: 30px;"></timepicker>
                    </div>

                </div>
                <a class="due-time clear" (click)="clearDueTime()" *ngIf = "showDueTime">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.CLEAR-DUE-TIME'| translate }}</a>

                <div *ngIf="viewMode" class="fs-14 lato-regular">
                    {{selectedWorkOrder.dueDate  | localizedDateShort | async}}
                </div>
            </div>
            <div class="d-flex align-items-center w-100 mb-4">
                <label class="fs-14 font-weight-600" style="width: 122px;">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.RECURRENCE'| translate }}</label>
                <!-- <input type="text" class="form-control ms-3 fs-14"> -->
                <div class="btn-group dropdown-wrapper-list ps-0 pe-0" dropdown style="width: 180px; border: none;" *ngIf="!viewMode">
                    <button type="button" class="btn btn-primary action-dropdown p-0 text-start ps-3 hover-style fs-14"
                            #toggleButton dropdownToggle>
                        {{ workOrderForm.get('recurrence')?.value }}
                    </button>
                    <button id="button-split" type="button" class="btn dropdown-toggle dropdown-toggle-split"
                            (click)="$event.stopPropagation(); toggleButton.click()">
                    </button>
                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu"
                        style="max-height: 240px; overflow-y: scroll;">
                        <li role="menuitem" *ngFor="let recurrence of recurrenceOptions">
                            <a class="dropdown-item fs-14 position-relative priority-item"
                               (click)="changeRecurrence(recurrence)">
                                {{ recurrence }}
                            </a>
                        </li>
                    </ul>
                </div>
                
                <div *ngIf="viewMode" class="fs-14 lato-regular">
                    {{ workOrderForm.get('recurrence')?.value }}
                </div>
            </div>
            <div class="d-flex align-items-center w-100 mb-4">
                <label class="fs-14 font-weight-600" style="width: 122px;">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.PRIORITY'| translate }}</label>
                <div class="btn-group dropdown-wrapper-list priority-wrapper-list ps-0 pe-0" dropdown style="width: 180px;border: none;" *ngIf="!viewMode">
                    <button type="button" class="btn btn-primary action-dropdown priority-dropdown p-0 text-start ps-4 hover-style"  #toggleButton dropdownToggle data-bs-auto-close="inside">
                        <span class="fs-20 expert-web-status {{priority.value}}">.</span>
                        {{ 'MAIN.CONTENT.WORK-ORDERS.PRIORITY.' + priority.value | translate }}
                    </button>
                    <button id="button-split" type="button"  class="btn dropdown-toggle dropdown-toggle-split priority-toggle"  (click)="$event.stopPropagation(); toggleButton.click()"
                            aria-controls="dropdown-split" data-bs-auto-close="false" auto-close="false">
                    </button>
                    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" onclick="event.stopPropagation()"  style="max-height: 240px; 
                    overflow-y: scroll; min-width: 110px;" aria-labelledby="dropdownMenuClickableInside">
                        <li role="menuitem" *ngFor="let priority of priorityOptions">
                            <a class="dropdown-item fs-15 position-relative priority-item" (click)="changePriority(priority)">
                                <span class="fs-20 status-list-item {{priority}}">.</span>
                                {{ 'MAIN.CONTENT.WORK-ORDERS.PRIORITY.' + priority | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="viewMode" class="fs-14 lato-regular p-relative">
                    <span class="fs-20 priority-status {{priority.value}}">.</span>
                    <span class="ps-3">{{ 'MAIN.CONTENT.WORK-ORDERS.PRIORITY.' + priority.value | translate }}</span>
                </div>
            </div>
            <div *ngIf="selectedWorkOrder" class="d-flex align-items-center w-100" [ngClass]="{'mt-1': !viewMode}">
                <label class="fs-14 font-weight-600" style="width: 122px;">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.ID'| translate }}</label>
                <label class="fs-14 lato-regular">WID-{{ selectedWorkOrder.wid }}</label>
            </div>
        </div>

        <div class="order-wrapper align-items-center">
            <label class="fs-14 font-weight-600 mb-3">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.DESCRIPTION'| translate }}</label>
            <textarea name="" id="" cols="30" rows="5" class="form-control fs-14" *ngIf="!viewMode" formControlName="description"></textarea>
            <p *ngIf="viewMode" class="fs-14 lato-regular mb-0">{{ selectedWorkOrder.description }}</p>
        </div>

        <div class="order-wrapper align-items-center">
            <label class="fs-14 font-weight-600 mb-3">{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.LABEL.TITLE' | translate }}</label>
            <div class="mb-4" *ngIf="!viewMode">
                <ng-select [items]="allLabels" [loading]="loading" formControlName="labels" [addTag]="addTagPromise.bind(this)" [multiple]="true" class="mt-2">

                    <ng-template ng-tag-tmp let-search="searchTerm">
                        <b>{{ 'MAIN.CONTENT.WORKFLOW.WORKFLOW-DETAIL.LABEL.CREATE' | translate }}</b>: {{search}}
                    </ng-template>
                </ng-select>
            </div>

            <div class="d-flex flex-wrap files-area" *ngIf="selectedWorkOrder?.labels?.length && viewMode">
                <div class="file-badge fs-14 me-3 mb-1" *ngFor="let label of selectedWorkOrder.labels; let i = index">
                    <label>{{label}}</label>
                    <img *ngIf="!viewMode" src="assets/img/close.svg" alt="" class="close-file" (click)="deleteItem(i, 'labels')">
                </div>
            </div>
        </div>

    <!-- Tab 0: Details -->
    <ng-container *ngIf="tabIndex == 0">
        <div class="order-wrapper align-items-center">
            <label class="fs-14 font-weight-600 mb-3">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.FILES' | translate }}</label>
            <div class="mb-4" *ngIf="!viewMode">
                <app-file-upload [id]="workOrderId" (uploadedFiles)="uploadedFilesChange($event)"></app-file-upload>
            </div>

            <!-- Highlight new files -->
            <div class="d-flex flex-wrap files-area" *ngIf="selectedWorkOrder?.files?.length">
                <div class="file-badge fs-14 me-3 mb-1"
                     *ngFor="let file of selectedWorkOrder.files; let i = index"
                     [ngClass]="{'new-file': i >= originalFileCount}">
                    <img src="assets/img/download-step-file.svg" alt="" class="download-file" (click)="downloadWorkOrderFile(file)">
                    <label>{{ file.name }}</label>
                    <img *ngIf="!viewMode" src="assets/img/close.svg" alt="" class="close-file" (click)="deleteFileFromWorkOrder(i, 'files')">
                </div>
            </div>
        </div>
    </ng-container>

        <div class="order-wrapper workflows-wrapper align-items-center">
            <div class="workflows-wrapper-background">
                <label class="fs-14 font-weight-600 mb-3">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.ASSIGNED-WORKFLOWS'| translate }}</label>
                <div class="d-flex flex-wrap">
                    <div class="d-flex me-2 mb-3" [ngClass]="{'workflow-card' : !viewMode}" *ngFor="let workflow of assignedWorkflows.value">
                        <span class="workflow-name fs-14 lato-regular" [ngClass]="{'file-badge': viewMode}">
                            {{getWorkflowNameById(workflow)}}
                        </span>
                        <span class="workflow-close" *ngIf="!viewMode">
                            <img src="assets/img/close.svg" alt="" height="8px" (click)="deleteWorkflowFromWorkOrder(0, 'assignedWorkflows')">
                        </span>
                    </div>
                </div>
                <div class="dropdown" *ngIf="!viewMode">
                    <button class="btn btn-secondary dropdown-toggle workflow-dropdown fs-14 lato-regular" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.ADD-WORKFLOW'| translate }}
                    </button>
                    <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenu2" style="max-height: 180px; overflow-y: scroll; border-radius:10px;">
                        <div class="search-box p-2">
                            <input  type="text" class="form-control fs-14" style="height: 38px;" [formControl]="workflowFilter"  placeholder="{{ 'MAIN.CONTENT.WORKFLOW.LANGUAGE.SEARCH' | translate }}" inputSelectOnFocus [inputSelectOnCreate]="true">
                        </div>
                        <div *ngFor="let workflow of filteredWorkflowList; let index = index" class="ps-2">
                            <div class="custom-control custom-checkbox custom-checkbox-green">
                                <input
                                    type="checkbox"
                                    class="form-check-input ms-0"
                                    [id]="index"
                                    (click) = selectWorkflow(workflow)
                                />
                                <label class="custom-control-label label-form fs-14" [for]="index">
                                    {{ workflow.published?.listName }}
                                </label>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </div>

        <div class="order-wrapper align-items-center">
            <label class="fs-14 font-weight-600 mb-3">Assigned Experts for Remote Call</label>
            <div class="dropdown" *ngIf="!viewMode">
                <button class="btn btn-secondary dropdown-toggle workflow-dropdown fs-14 lato-regular" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    {{  selectedExpert ? selectedExpert : 'MAIN.CONTENT.WORK-ORDERS.ITEMS.SELECT-USER'| translate }}
                </button>
                <ul class="dropdown-menu w-100 pt-0" aria-labelledby="dropdownMenu2" style="border-radius: 10px;max-height: 172px; overflow: scroll;">
                    <!-- <div class="search-box">
                        <input type="text" class="form-control filter fs-14" [formControl]="userFilter">

                    </div> -->
                <li *ngFor="let user of expertList"><button class="dropdown-item fs-14 pt-2 pb-2" type="button" (click)="selectExpert(user)">{{user.name}}</button></li>
                </ul>
            </div>
            <div *ngIf="viewMode && selectedExpert" class="fs-14">{{selectedExpert}}</div>
        </div>

        <div class="order-wrapper align-items-center">
            <label class="fs-14 font-weight-600 mb-3">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.ASSIGNED-TO'| translate }}</label>
            <div class="dropdown" *ngIf="!viewMode">
                <button class="btn btn-secondary dropdown-toggle workflow-dropdown fs-14 lato-regular" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    {{  selectedUser ? selectedUser : 'MAIN.CONTENT.WORK-ORDERS.ITEMS.SELECT-USER'| translate }}
                </button>
                <ul class="dropdown-menu w-100 pt-0" aria-labelledby="dropdownMenu2" style="border-radius: 10px;max-height: 172px; overflow: scroll;">
                    <div class="search-box">
                        <input type="text" class="form-control filter fs-14" [formControl]="userFilter">

                    </div>
                <li *ngFor="let user of filteredUserList"><button class="dropdown-item fs-14 pt-2 pb-2" type="button" (click)="selectUser(user)">{{user.name}}</button></li>
                </ul>
            </div>
            <div *ngIf="viewMode && selectedUser" class="fs-14">{{selectedUser}}</div>
        </div>

        <div class="order-wrapper align-items-center" *ngIf="mode !=='create'">
            <label class="fs-14 font-weight-600 mb-3">{{ 'MAIN.CONTENT.WORK-ORDERS.ITEMS.ACTIVITY'| translate }}</label>
            <div *ngIf="selectedWorkOrder?.activities?.length > 0">
                <div *ngFor="let activity of selectedWorkOrder.activities.slice().reverse()">
                    <div class="d-flex align-items-center justify-content-between fs-14 lato-regular mb-2">
                        <div class="col-10" *ngIf="activity.field">
                            {{getUsersName(activity.userId)}} {{ 'MAIN.CONTENT.WORK-ORDERS.ACTIVITY.UPDATED'| translate }}
                            {{ 'MAIN.CONTENT.WORK-ORDERS.ACTIVITY.UPDATED'| translate }} {{activity.field}}.
                        </div>
                        <div class="col-10" *ngIf="!activity.field">
                            {{getUsersName(activity.userId)}} {{ 'MAIN.CONTENT.WORK-ORDERS.ACTIVITY.WORK-ORDER-CREATED'| translate }}.
                        </div>
                        <div class="col-2">{{activity.createdAt | localizedDateShort | async}}</div>
                    </div>
                </div>
            </div>
        </div>
   </ng-container>

   <ng-container *ngIf="tabIndex == 1">
        <app-comment [workOrderId]="workOrderId" [comments]="selectedWorkOrder?.comments"></app-comment>
   </ng-container>
</div>